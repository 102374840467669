import * as React from "react"
import { graphql, Link } from "gatsby"
import FacebookIcon from "../images/facebook_icon.png"
import RedditIcon from "../images/reddit_icon.svg"
import TwitterIcon from "../images/twitter_icon.svg"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from 'react-i18next'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { MDXProvider } from "@mdx-js/react"
import { ExternalLink } from "../components/utils/mdxComponents"
import { localizeString } from "../components/utils/localization"
import { MDXRenderer } from "gatsby-plugin-mdx"



function BlogPostPage({data: { thisBlog, blogs }, children}) {
    const { t, i18n } = useTranslation();
const {title, slug, date, thumb, thumbALT, author, localImages} = thisBlog.frontmatter;
const nodes = blogs.nodes;
const bloglinks = nodes.map((n) => n.childMdx.frontmatter.slug);
const thisIndex = bloglinks.indexOf(slug);
const nextSlug = thisIndex < (bloglinks.length - 1)? bloglinks[thisIndex+1] : bloglinks[0];
const previousSlug = thisIndex > 0? bloglinks[thisIndex-1] : bloglinks[bloglinks.length-1];

const BlogImage = props => {
	return <GatsbyImage image={getImage(localImages[props.index])} alt={props.alt}></GatsbyImage>
}

const components = {
  a: ExternalLink,
  BlogImage
}

    return (
        <Layout type = "template" isDark={false} isMobile={false} key={i18n.language}>
           <div className = "project-page">
            <section className="subpage-title-section portfolio-title-section" id="project-title-section">
                <div className="dark heading">
                    <span className="dark post-date">{date}</span>
                    <h1 className="subpage-title"><span id="project-title">{title}</span></h1>
                </div>
                
            </section>
            <section className="project-wrapper">
              <GatsbyImage image={getImage(thumb)} alt={thumbALT} className="project-title-img"></GatsbyImage>
              <div className="project-paragraph blog-paragraph dark">
                <MDXProvider components={components}>
                  <MDXRenderer>
                    {thisBlog.body}
                  </MDXRenderer>
                </MDXProvider>
              </div>
            </section>
            <section className="blog-share">
                <div><span className="dark">- by {author}</span></div>
                <div className="social-icons flex">
                    <div className="row-1"><span className="dark">{t("blogsharethispost")}</span></div>
                    <div className="row-2">
                        <ul className="social-media-list-desktop flex">
                            <li><a href={"https://www.facebook.com/sharer/sharer.php?u=https://vanilla-noir.com" + localizeString("/blog/") + slug} target="_blank" rel="noopener noreferrer"><img height="20px" src={FacebookIcon} alt="Facebook icon"/></a></li>
                            <li><a href ={"https://twitter.com/intent/tweet?url=https://vanilla-noir.com" + localizeString("/blog/") + slug} target="_blank" rel="noopener noreferrer"> <img height="20px" src={TwitterIcon} alt="Twitter icon"/></a></li>
                            <li><a href ={"https://www.reddit.com/submit?url=https://vanilla-noir.com" + localizeString("/blog/") + slug} target="_blank" rel="noopener noreferrer"> <img height="20px" src={RedditIcon} alt="Reddit icon"/></a></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="post-navigation dark" id="project-navigation">
                <div className="post-nav flex">
                  <Link to={localizeString("/blog/") + previousSlug}><span>{t("previous")}</span><span className="desktop-only">&nbsp;{t("post")}</span></Link>
                  <Link to={localizeString("/blog/")}><span>{t("back")}</span><span className="desktop-only">&nbsp;{t("toallposts")}</span></Link>
                  <Link to={localizeString("/blog/") + nextSlug}><span>{t("next")}</span><span className="desktop-only">&nbsp;{t("post")}</span></Link>
                </div>
            </section>
            </div> 


        </Layout>
    )
}

export const query = graphql`
query ($locale: String, $slug: String) {
  thisBlog: mdx(
    fields: { locale: { eq: $locale } }
    frontmatter: {slug: {eq: $slug}}) {
      body
    frontmatter {
      slug
      title
      shortdescription
      date
      thumbALT
      thumb {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      localImages {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      author
    }
  }
  blogs: allFile(
    filter: {
      sourceInstanceName: { eq: "blogs"}
      childMdx: { fields: { locale: { eq: $locale }}}
    },
    sort: {fields: childrenMdx___frontmatter___order, order: DESC})
    {
      nodes {
        childMdx{
        id
        frontmatter {
          slug
        }
        }
      }
    }
}



`

  
  export default BlogPostPage
  
  export const Head = ({data}) => (
    <Seo title={data.thisBlog.frontmatter.title} description={data.thisBlog.frontmatter.shortdescription} titleDE={data.thisBlog.frontmatter.title} descriptionDE={data.thisBlog.frontmatter.shortdescription} image={getSrc(data.thisBlog.frontmatter.thumb)}/>
    )